import {
  CommentForUser,
  CommentsForUserResponseModel,
  commentsAPI,
} from '@capturi/api-comments'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { useCurrentUser } from '@capturi/core'
import { ErrorBoundary, usePageTitle } from '@capturi/react-utils'
import request, { generateImageUrl } from '@capturi/request'
import { useOrganization, useUsers } from '@capturi/stores'
import {
  Button,
  ContentPlaceholder,
  Description,
  Join,
  List,
  ListItem,
  PageHeading,
  Spinner,
} from '@capturi/ui-components'
import {
  Avatar,
  Box,
  Divider,
  Flex,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { ButtonLink } from 'components/Link'
import RelativeTimeFormat from 'components/RelativeTime'
import conversationRoutes from 'pages/Conversations/routes'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { Event, log } from './events'

const TooltipDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const ConversationDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}
const CommentItem: React.FC<CommentForUser> = ({
  createdByUserUid,
  text,
  recipientUserUids,
  created,
  conversationSubject,
  conversationDateTime,
}) => {
  const { conversationLanguage } = useOrganization()
  const compare = Intl.Collator(conversationLanguage).compare
  const currentUser = useCurrentUser()
  const { getUserByUid } = useUsers()
  const createdBy = getUserByUid(createdByUserUid)
  const profileImageUrl = generateImageUrl(createdBy.profileImage?.key, {
    size: 32,
  })
  const isCreatedByCurrentUser = currentUser.id === createdByUserUid

  const recipients = React.useMemo(() => {
    return recipientUserUids.map(getUserByUid).sort((a, b) => {
      if (currentUser.id === a.uid) {
        return -1
      }
      if (currentUser.id === b.uid) {
        return 1
      }
      return compare(a.name, b.name)
    })
  }, [recipientUserUids, getUserByUid, currentUser.id, compare])

  const formattedDate = i18n.date(created, TooltipDateFormatOptions)
  return (
    <Flex flex="1">
      <Avatar size="sm" src={profileImageUrl} name={createdBy.name} mr={4} />
      <Box w="100%">
        <Box>
          <Flex align="baseline" justify="space-between">
            <Text fontWeight="medium" textTransform="capitalize">
              {isCreatedByCurrentUser ? t`you` : createdBy.name}
            </Text>
            <Tooltip hasArrow label={formattedDate} aria-label={formattedDate}>
              <Description display="inline" fontSize="xs" ml={2}>
                <RelativeTimeFormat date={created} />
              </Description>
            </Tooltip>
          </Flex>
          <Description
            as="div"
            fontSize="sm"
            display="flex"
            flexWrap="wrap"
            alignItems="center"
          >
            <Trans>To</Trans>
            <span>&nbsp;</span>
            <Join
              values={recipients}
              separator={(isLast) => (isLast ? ` ${t`and`} ` : ', ')}
            >
              {(user) => (user.uid === currentUser.id ? t`you` : user.name)}
            </Join>
            <Divider orientation="vertical" mx={2} minH={3} />
            <Box>
              <Text as="span">{conversationSubject}, </Text>
              {i18n.date(conversationDateTime, ConversationDateFormatOptions)}
            </Box>
          </Description>
        </Box>
        <Text
          mt={2}
          whiteSpace="pre-wrap"
          // overflow-wrap: anywhere is not supported by Safari there we add the deprecated word-break: break-word as well
          wordBreak="break-word"
          overflowWrap="anywhere"
        >
          {text}
        </Text>
      </Box>
    </Flex>
  )
}

const PAGE_SIZE = 30
const getNextPageParam = (
  lastPage: CommentsForUserResponseModel,
  pages: CommentsForUserResponseModel[],
) => {
  if (lastPage.comments.length < PAGE_SIZE) {
    // Return undefined to indicate there is no next page available
    return undefined
  }
  return {
    pageNumber: pages.length,
    pageSize: PAGE_SIZE,
    sortDirection: 1,
  }
}

const CommentsPage: React.FC = () => {
  usePageTitle(t`Comments`)
  const { id: userUid } = useCurrentUser()
  const navigate = useNavigate()

  const handleGoToConversation = (item: CommentForUser): void => {
    if (item?.conversationUid != null) {
      navigate({
        pathname: conversationRoutes.conversation(
          item.conversationUid,
          'comments',
        ),
        search: new URLSearchParams({
          time: String(item.when - 3),
        }).toString(),
      })
      log(Event.GoToConversation)
    }
  }

  const { data, fetchNextPage, error, hasNextPage, isFetchingNextPage } =
    useSuspenseInfiniteQuery({
      queryKey: ['comments', userUid],
      queryFn: async ({ pageParam }) =>
        await request.get<CommentsForUserResponseModel>(
          commentsAPI.getAllComments(userUid).url,
          {
            query: { ...pageParam, 'api-version': '3.3' },
          },
        ),
      getNextPageParam,
      initialPageParam: {
        pageNumber: 0,
        pageSize: PAGE_SIZE,
        sortDirection: 1,
      },
    })

  const isLoadingInitialData = !(data || error)
  const isEmpty = data?.pages?.[0].comments?.length === 0

  const handleLoadMoreClicked = (): void => {
    fetchNextPage()
    log(Event.LoadMore)
  }

  return (
    <ErrorBoundary>
      <Box>
        <PageHeading mb={8}>
          <Trans>Comments</Trans>
        </PageHeading>

        <Box>
          {isLoadingInitialData && <Spinner display="block" m="1rem auto" />}

          {isEmpty && (
            <ContentPlaceholder.Container mt="10vh">
              <ContentPlaceholder.Image as={Icon_EmptyState} />
              <ContentPlaceholder.Heading>
                <Trans>It&apos;s a bit empty here!</Trans>
              </ContentPlaceholder.Heading>
              <ContentPlaceholder.Body>
                <Trans>
                  Here you will be able to get an overview of all your comments
                  when you add them to conversations
                </Trans>
              </ContentPlaceholder.Body>
              <ContentPlaceholder.Footer>
                <ButtonLink primary to={conversationRoutes.list()}>
                  <Trans>Go to conversations</Trans>
                </ButtonLink>
              </ContentPlaceholder.Footer>
            </ContentPlaceholder.Container>
          )}
          <Stack spacing={0} shouldWrapChildren>
            <List disablePadding>
              {(data?.pages ?? []).map((page) =>
                page.comments.map((c) => (
                  <ListItem
                    key={c.uid}
                    hasDivider
                    button
                    onClick={() => handleGoToConversation(c)}
                    aria-label={i18n._(t`Go to conversation`)}
                  >
                    <CommentItem {...c} />
                  </ListItem>
                )),
              )}
            </List>
          </Stack>
          {!isEmpty && (
            <Flex justifyContent="center" py={2} px={4}>
              <Button
                onClick={handleLoadMoreClicked}
                isDisabled={!hasNextPage || isFetchingNextPage}
                isLoading={isFetchingNextPage}
                size="sm"
              >
                {!hasNextPage ? (
                  <Trans>No more comments</Trans>
                ) : (
                  <Trans>Load more</Trans>
                )}
              </Button>
            </Flex>
          )}
        </Box>
      </Box>
    </ErrorBoundary>
  )
}

export default React.memo(CommentsPage)
